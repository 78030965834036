/* src/styles/HeroSection.css */
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Playfair+Display:wght@400;700&display=swap");
@import url("./variables.css");
@import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap");

.hero-page {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  min-height: 100vh; /* Ensure it takes the full height */
  padding-top: 60px; /* Add padding to avoid overlap with Navbar */
  padding-bottom: 40px; /* Add padding to avoid overlap with Footer */
  background-image: url("../assets/images/holdingKellyForestBG.jpg");
  background-size: cover;
  background-position: center;
}

.hero-container {
  padding: 50px 50px; /* Increased padding */
  margin: 10px; /* Added margin */
  border-radius: 10px; /* Added border radius */
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
}

.hero-container h1 {
  font-size: 5em; /* Increase font size */
  font-family: var(--font-secondary);
  margin-bottom: 100px;
  color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
}

.countdown {
  display: flex;
  gap: 10px;
  margin-top: 50px;
  font-size: 1.2em;
  font-family: var(--font-primary); /* Use Playfair Display */
  justify-content: center;
  align-items: center;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown-item .time {
  font-size: 2em; /* Adjust font size as needed */
}

.colon {
  display: flex;
  align-items: center;
  font-size: 2em; /* Adjust font size to match digits */
  line-height: 1; /* Ensure colons are vertically centered */
  margin-top: -0.7em; /* Adjust this value to align colons with digits */
}

.event-details {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 100px;
  width: 100%; /* Make the date and location section wider */
}

.event-info {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px 40px;
  border-radius: 30px;
  width: 100%; /* Ensure the event-info takes the full width */
}

.event-date,
.event-location {
  font-family: var(--font-primary); /* Use Playfair Display */
  color: #333;
  flex: 1; /* Allow the date and location to take equal space */
  text-align: center; /* Center the text */
  font-size: 1.3em;
}

.divider {
  width: 2px;
  height: 60px;
  background: #333;
  margin: 0 20px;
}

.rsvp-button {
  background: transparent;
  color: white;
  padding: 10px 30px;
  border: 2px solid white;
  border-radius: 50px;
  font-size: 1.2em;
  cursor: pointer;
  transition:
    background 0.3s ease,
    transform 0.3s ease;
}

.rsvp-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .hero-container h1 {
    font-size: 5em;
  }

  .countdown {
    font-size: 1.1em;
  }
}

@media (max-width: 992px) {
  .hero-container h1 {
    font-size: 4em;
  }

  .countdown {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .hero-container h1 {
    font-size: 3em;
  }

  .countdown {
    font-size: 0.9em;
  }

  .event-info {
    flex-direction: column;
    padding: 20px;
  }

  .divider {
    width: 60px;
    height: 2px;
    margin: 20px 0;
  }
}

@media (max-width: 576px) {
  .hero-container h1 {
    font-size: 2.5em;
  }
  .countdown {
    font-size: 0.8em;
    gap: 10px;
  }
}
