@import url("./variables.css");

html,
body,
#root {
  height: 100%;
  margin: 0;
}

.password-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-image: url("../assets/images/kellyBenApartHoldingHands.jpg");
  background-size: cover;
  background-position: center;
}

.password-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-top: 20px; /* Add some padding to the top */
}

.title-paper {
  padding: 15px !important;
  background: rgba(255, 255, 255, 0.9) !important;
  border-radius: 10px;
  margin-bottom: 5px !important; /* Add some margin to separate from the rest */
}

.site-title {
  font-family: var(--font-secondary) !important;
  color: #000000 !important;
  font-size: 2.5em; /* Default font size */
}

.site-subtitle {
  font-family: var(--font-primary) !important;
  color: var(--color-visible-text) !important;
  margin-bottom: 20px;
}

.password-paper {
  padding: 20px;
  max-width: 400px;
  width: 100%;
  background: rgba(255, 255, 255, 0.9) !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  text-align: center;
  margin-top: 20px; /* Add some margin to separate from the titles */
}

.password-title {
  font-family: var(--font-secondary) !important;
  color: var(--color-visible-text) !important;
  font-size: 1.8rem !important;
}

.password-textfield {
  margin-bottom: 20px;
}

.password-error {
  margin-top: 10px;
}

.password-button {
  background-color: black !important;
  color: white !important;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.password-button:hover {
  background-color: white !important;
  color: black !important;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .site-title {
    font-size: 1em !important; /* Smaller font size for tablets */
  }

  .password-title {
    font-size: 1rem !important;
  }

  .password-paper {
    padding: 15px; /* Reduce padding */
    max-width: 90%; /* Allow more width on smaller screens */
  }
}

@media (max-width: 480px) {
  .site-title {
    font-size: 0.8em; /* Smaller font size for mobile */
  }

  .password-title {
    font-size: 0.8rem !important;
  }

  .password-paper {
    padding: 10px; /* Further reduce padding */
    max-width: 95%; /* Allow even more width on smaller screens */
  }
}
