.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Transparent white background */
  color: #000; /* Black font color */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px; /* Rounded edges */
  margin: 0 auto; /* Center the footer */
  transition: transform 0.3s ease;
  width: fit-content; /* Width adjusts to content */
  max-width: calc(100% - 20px); /* Ensure it doesn't exceed screen width */
  margin-bottom: 10px;
}

.footer.visible {
  transform: translateY(0);
}

.footer-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto; /* Width adjusts to content */
}

.footer p {
  margin: 0;
}

.footer a {
  color: #000; /* Black font color */
  text-decoration: none;
  transition:
    color 0.3s ease,
    text-decoration 0.3s ease;
}

.footer a:hover {
  color: #dfc42e; /* Gold color on hover */
  text-decoration: underline;
}
