@import url("./variables.css");

.admin-dashboard {
  padding: 40px !important;
  background: #f9f9f9 !important;
  border-radius: 10px !important;
  margin: 20px auto !important;
  max-width: 1200px !important;
  margin-top: 10vh !important;
  margin-bottom: 10vh !important;
  font-family: var(--font-tertiary) !important;
}

.dashboard-title {
  font-family: var(--font-primary) !important;
  font-weight: 700 !important;
  text-align: center !important;
  margin-bottom: 40px !important;
}

.chart-container {
  padding: 20px !important;
  text-align: center !important;
  margin: 0 auto 20px auto !important;
  width: 100% !important;
  background-color: #ffffff !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.info-section {
  padding: 20px !important;
  margin-top: 40px !important;
  background-color: #ffffff !important;
  border-radius: 10px !important;
}

.rsvp-list,
.guest-list {
  margin-top: 40px !important;
  padding: 20px !important;
  background-color: "grey" !important;
  border-radius: 10px !important;
}

.guest-list th {
  cursor: pointer;
  background-color: "white";
  color: black;
  padding: 10px;
  text-align: left;
}

.guest-list th:hover {
  background-color: #555;
}

.guest-list td {
  padding: 10px;
  text-align: left;
}

.MuiTableCell-root {
  font-family: var(--font-tertiary) !important;
}

.custom-button {
  margin: 10px !important;
  padding: 10px 20px !important;
  border: 2px solid #000000 !important;
  background: none !important;
  color: #000000 !important;
  font-family: var(--font-tertiary) !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
}

.custom-button:hover {
  background: #000000 !important;
  color: #ffffff !important;
}

@media (max-width: 960px) {
  .chart-container {
    margin-bottom: 30px !important;
  }
}

@media (max-width: 600px) {
  .admin-dashboard {
    padding: 20px !important;
  }

  .chart-container,
  .rsvp-list,
  .guest-list,
  .info-section {
    padding: 15px !important;
  }

  .dashboard-title {
    font-size: 2em !important;
  }
}
