/* src/index.css */
body {
  margin: 0;
  font-family: "Georgia", serif;
  background-color: #f4f4f9;
  color: #333;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
