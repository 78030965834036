.under-construction {
  text-align: center;
  padding: 50px;
}

.animation {
  margin-top: 20px;
  font-size: 2em;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
