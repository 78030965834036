@import url("./variables.css");

.wedding-party-container {
  padding: 6rem 2rem !important; /* Adjusted padding for smaller devices */
  background-color: white !important; /* Use variable for background color */
  margin-top: 5vh; /* Ensure content is below the navbar */
}

.title {
  font-family: var(--font-secondary) !important;
  text-align: center !important;
  margin-bottom: 2rem !important;
  color: var(--color-visible-text) !important;
  font-size: 3rem !important;
}

.wedding-party-box {
  border: 4px solid var(--color-tertiary) !important;
  border-radius: 10px !important;
  background-color: white !important;
  padding: 1rem !important;
  text-align: center !important;
  cursor: pointer !important;
  transition: transform 0.3s ease-in-out !important;
  margin: 1rem !important;
  width: 95% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  min-height: 300px !important; /* Minimum height to prevent smushing */
}

.wedding-party-box:hover {
  transform: scale(1.05) !important;
}

.image-container {
  position: relative !important;
  overflow: hidden !important;
  border-radius: 50% !important;
  margin: 0 auto 1rem auto !important;
  width: 60% !important; /* Adjusted width to be responsive */
  height: 0 !important;
  padding-bottom: 60% !important; /* Maintain aspect ratio */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-width: 150px !important; /* Minimum width to prevent smushing */
  min-height: 150px !important; /* Minimum height to prevent smushing */
}

.image-container .wedding-party-image {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: center !important;
  border-radius: 50% !important; /* Ensure circular shape */
}

.wedding-party-image {
  width: 100% !important; /* Adjusted width to be responsive */
  height: 100% !important; /* Adjusted height to be responsive */
  object-fit: cover !important;
  object-position: center !important;
  border-radius: 50% !important; /* Ensure circular shape */
}

.name {
  font-family: var(--font-primary) !important;
  font-size: 1.5rem !important;
  color: var(--color-visible-text) !important;
  font-weight: bold !important;
}

.role {
  font-family: var(--font-primary) !important;
  font-size: 1.2rem !important;
  color: var(--color-visible-text) !important;
  font-weight: bold !important;
}

.description {
  font-family: var(--font-primary) !important;
  color: var(--color-visible-text) !important;
  margin-top: 0.5rem !important;
  font-size: 1rem !important;
  text-align: center !important;
}

.dialog-content {
  background-color: white !important;
  padding: 2rem !important;
  border-radius: 10px !important;
}

.dialog-inner-content {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.dialog-title {
  font-family: var(--font-primary) !important;
  color: black !important;
  font-size: 4rem !important; /* Increased font size */
}

.dialog-role {
  font-family: var(--font-tertiary) !important;
  color: black !important;
  margin-top: 1rem !important;
  font-size: 1.8rem !important; /* Increased font size */
}

.dialog-description {
  font-family: var(--font-tertiary) !important;
  color: black !important;
  margin-top: 1rem !important;
  text-align: center !important;
  font-size: 1.5rem !important; /* Increased font size */
}

.dialog-image {
  width: 100% !important; /* Make the image fill the width of the modal */
  max-width: 500px !important; /* Set a maximum width */
  height: auto !important; /* Maintain aspect ratio */
  border-radius: 10% !important; /* Rounded edges */
  margin-bottom: 1rem !important;
}

.wedding-party-dialog-paper {
  background-color: var(--color-tertiary) !important;
  border-radius: 10px !important;
  padding: 2rem !important;
}

.wedding-party-typography-h5 {
  font-family: var(--font-primary) !important;
  color: black !important;
}

.wedding-party-typography-body1 {
  font-family: var(--font-primary) !important;
  color: black !important;
  line-height: 1.6 !important;
}

@media (max-width: 768px) {
  .wedding-party-container {
    margin-top: 7vh; /* Ensure content is below the navbar */
  }

  .wedding-party-container {
    padding: 2rem 1rem !important; /* Further adjust padding for smaller devices */
  }

  .wedding-party-box {
    height: auto !important; /* Adjust height for smaller devices */
  }

  .image-container {
    width: 60% !important; /* Adjust width for smaller devices */
    padding-bottom: 60% !important; /* Maintain aspect ratio */
  }

  .dialog-image {
    width: 100% !important; /* Make the image fill the width of the modal */
    max-width: 300px !important; /* Set a maximum width for smaller devices */
    height: auto !important; /* Maintain aspect ratio */
  }

  .title {
    font-size: 2.5rem !important; /* Adjust font size for smaller devices */
  }

  .name {
    font-size: 1.2rem !important; /* Adjust font size for smaller devices */
  }

  .role {
    font-size: 1rem !important; /* Adjust font size for smaller devices */
  }

  .description {
    font-size: 0.9rem !important; /* Adjust font size for smaller devices */
  }

  .dialog-title {
    font-size: 3rem !important; /* Adjust font size for smaller devices */
  }

  .dialog-role {
    font-size: 1.5rem !important; /* Adjust font size for smaller devices */
  }

  .dialog-description {
    font-size: 1.2rem !important; /* Adjust font size for smaller devices */
  }
}

@media (max-width: 480px) {
  .wedding-party-container {
    margin-top: 10vh; /* Ensure content is below the navbar */
  }

  .wedding-party-container {
    padding: 1rem 0.5rem !important; /* Further adjust padding for smallest devices */
  }

  .wedding-party-box {
    height: auto !important; /* Adjust height for smallest devices */
  }

  .image-container {
    width: 70% !important; /* Adjust width for smallest devices */
    padding-bottom: 70% !important; /* Maintain aspect ratio */
  }

  .dialog-image {
    width: 100% !important; /* Make the image fill the width of the modal */
    max-width: 200px !important; /* Set a maximum width for smallest devices */
    height: auto !important; /* Maintain aspect ratio */
  }

  .title {
    font-size: 2rem !important; /* Adjust font size for smallest devices */
  }

  .name {
    font-size: 1rem !important; /* Adjust font size for smallest devices */
  }

  .role {
    font-size: 0.9rem !important; /* Adjust font size for smallest devices */
  }

  .description {
    font-size: 0.8rem !important; /* Adjust font size for smallest devices */
  }

  .dialog-title {
    font-size: 2.5rem !important; /* Adjust font size for smallest devices */
  }

  .dialog-role {
    font-size: 1.2rem !important; /* Adjust font size for smallest devices */
  }

  .dialog-description {
    font-size: 1rem !important; /* Adjust font size for smallest devices */
  }
}
