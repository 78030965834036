@import url("./variables.css");

.rsvp-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("../assets/images/holdingKellyLaughing.jpg"),
    url("../assets/images/holdingKellyHoldingUpRing.jpg");
  background-size: 50% 100%;
  background-position: left, right;
  background-repeat: no-repeat;
}

.rsvp-container {
  padding: 60px 50px !important;
  width: 80% !important;
  max-width: 600px !important;
  margin: 20px !important;
  background-color: rgba(255, 255, 255, 0.95) !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.rsvp-title {
  font-family: var(--font-primary) !important;
  font-size: 3em !important;
  font-weight: 900 !important;
  text-align: center !important;
  color: var(--color-tertiary) !important;
  margin: 30px 0 !important;
}

.rsvp-subtitle {
  font-family: var(--font-primary);
  font-size: 1.5em;
  margin-top: 20px;
  color: var(--color-tertiary);
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

.rsvp-textfield,
.rsvp-formcontrol {
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
}

.rsvp-textfield input,
.rsvp-formcontrol input,
.rsvp-formcontrol .MuiSelect-root {
  font-family: var(--font-secondary);
}

.MuiInputLabel-root {
  font-family: var(--font-primary) !important;
  font-size: 1em !important;
}

.rsvp-button {
  font-family: var(--font-tertiary) !important;
  font-size: 1em !important;
  width: 30% !important;
  max-width: 300px !important;
  min-width: 80px !important;
  margin: 10px !important;
}

.rsvp-button-containedPrimary {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.rsvp-button-containedPrimary:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid black !important;
}

.rsvp-button-outlinedSecondary {
  color: #000000 !important;
  border-color: #000000 !important;
  background-color: transparent !important;
}

.rsvp-button-outlinedSecondary:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.rsvp-button-outlinedDefault {
  color: #000000 !important;
  border-color: #000000 !important;
  background-color: transparent !important;
}

.rsvp-button-outlinedDefault:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.rsvp-snackbar {
  top: 80px !important;
}

.rsvp-dialog .MuiPaper-root {
  padding: 20px !important;
  border-radius: 10px !important;
}

.rsvp-dialog-title {
  font-family: var(--font-primary) !important;
  font-size: 1.5em !important;
  font-weight: 700 !important;
  text-align: center !important;
  color: var(--color-tertiary) !important;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .rsvp-page {
    background-image: url("../assets/images/holdingKellyLaughing.jpg") !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
  }

  .rsvp-container {
    padding: 40px 30px;
    margin: 15px;
  }

  .rsvp-title {
    font-size: 2.5em !important;
  }

  .rsvp-button {
    font-size: 0.8em !important;
    width: 30% !important;
  }

  .rsvp-textfield,
  .rsvp-formcontrol {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .rsvp-page {
    background-image: url("../assets/images/holdingKellyLaughing.jpg") !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
  }

  .rsvp-container {
    padding: 30px 20px;
    margin: 10px;
  }

  .rsvp-title {
    font-size: 2em !important;
  }

  .rsvp-textfield,
  .rsvp-formcontrol {
    margin-bottom: 8px;
  }

  .rsvp-button {
    font-size: 0.7em !important;
    width: 30% !important;
  }
}
