@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Playfair+Display:wght@400;700&display=swap");
@import "./variables.css";

.travel-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure it takes the full height */
  padding-top: 60px; /* Add padding to avoid overlap with Navbar */
  padding-bottom: 40px; /* Add padding to avoid overlap with Footer */
  background-image: url("../assets/images/kellyOnBensBack.jpg");
  background-size: cover;
  background-position: center;
}

.travel-container {
  text-align: center;
  padding: 50px 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px; /* Added border radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added box shadow */
  color: var(--color-primary); /* Use primary color for text */
  font-weight: bold;
  width: 100%;
  max-width: 1200px;
}

.travel-title {
  font-size: 3em;
  margin-bottom: 50px;
  font-family: var(--font-secondary) !important; /* Use secondary font */
  color: #a5c3ac;
}

.travel-sections {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.travel-section {
  background: rgb(255, 255, 255) !important; /* Use white background */
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    background 0.3s ease,
    color 0.3s ease; /* Added transition for color */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added box shadow */
}

.travel-section:hover {
  background-color: var(--color-primary) !important; /* Use primary color */
  transform: scale(1.05) !important;
}

.travel-section:hover .travel-icon {
  color: white !important; /* Change icon color to white on hover */
}

.travel-section:hover .travel-text {
  color: white !important; /* Change text color to white on hover */
}

.travel-icon {
  font-size: 3em !important;
  color: var(--color-tertiary) !important; /* Use tertiary color */
}

.travel-text {
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--color-tertiary) !important; /* Use tertiary color */
}

.carousel-item {
  position: relative;
  text-align: center;
}

.carousel-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(82, 80, 80, 0.6);
  color: var(--color-quaternary) !important;
  padding: 10px 20px;
  border-radius: 10px;
}

.carousel-caption h5 {
  margin: 0;
  font-size: 1.5em;
  font-family: var(--font-primary) !important; /* Use primary font */
  color: var(--color-quaternary) !important; /* Use quaternary color */
}

.carousel-caption p {
  margin: 5px 0;
  font-size: 1em;
  color: var(--color-quaternary) !important; /* Use quaternary color */
}

.carousel-caption a {
  color: var(--color-quaternary) !important; /* Use quaternary color */
  text-decoration: none;
}

.carousel-caption a:hover {
  text-decoration: underline;
}

.carousel-title {
  font-family: var(--font-secondary) !important; /* Use primary font */
  color: black !important; /* Use black color */
}

.carousel-description {
  font-family: var(--font-primary) !important; /* Use primary font */
  color: black !important; /* Use black color */
}

.carousel-link {
  font-family: var(--font-primary) !important; /* Use primary font */
  color: #a5c3ac;
  text-decoration: underline !important;
}

.carousel-address {
  font-family: var(--font-primary) !important; /* Use primary font */
  color: black !important; /* Use tertiary color */
  font-size: 0.9em;
}

.travel-dialog-paper {
  background-color: white !important;
  border-radius: 10px !important;
  padding: 0 !important; /* Remove default padding */
}

.travel-dialog-title {
  font-family: var(--font-primary) !important;
  color: black !important; /* Use black color */
  font-size: 2rem !important;
  padding: 1rem 2rem 0 2rem !important; /* Add padding to the right to avoid overlap with close button */
}

.travel-dialog-close-button {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
  color: black !important; /* Use black color */
}

.travel-dialog-content {
  font-family: var(--font-primary) !important;
  color: black !important; /* Use black color */
  font-size: 1.2rem !important;
  padding: 0 !important; /* Remove default padding */
  margin: 0 !important; /* Remove default margin */
}

.custom-carousel {
  width: 100% !important; /* Ensure the carousel takes up the full width */
  margin: 0 !important; /* Remove default margin */
}

.custom-carousel .carousel .slide {
  background: none !important;
  padding: 15px 10px !important; /* Adjust padding: more on top, less on sides */
}

.custom-carousel .carousel .control-arrow {
  background: rgba(0, 0, 0, 0.6) !important;
  height: 100% !important;
  margin-top: 15px !important;
}

.custom-carousel .carousel .control-arrow:hover {
  background: rgba(0, 0, 0, 0.8) !important;
}

@media (max-width: 768px) {
  .travel-container {
    padding: 40px 30px;
    margin: 15px;
  }
  .travel-title {
    font-size: 2.5em !important;
  }
  .travel-section {
    width: 30%;
  }
  .travel-dialog-title {
    font-size: 1.8rem !important;
    padding-right: 1rem !important; /* Adjust padding for smaller screens */
  }
  .travel-dialog-content {
    font-size: 1rem !important; /* Adjust font size for smaller screens */
  }
  .custom-carousel {
    width: 100% !important; /* Ensure the carousel takes up the full width */
  }
}

@media (max-width: 480px) {
  .travel-container {
    padding: 30px 20px;
    margin: 10px;
  }
  .travel-title {
    font-size: 2em !important;
  }
  .travel-section {
    width: 45%;
  }
  .travel-dialog-title {
    font-size: 1.5rem !important;
    padding-right: 0.5rem !important; /* Adjust padding for smallest screens */
  }
  .travel-dialog-content {
    font-size: 0.9rem !important; /* Adjust font size for smallest screens */
  }
  .custom-carousel {
    width: 100% !important; /* Ensure the carousel takes up the full width */
  }
}
