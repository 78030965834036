@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Playfair+Display:wght@400;700&display=swap");
@import url("./variables.css");

.our-story-page {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 100vh !important;
  padding-top: 100px !important; /* Add padding to avoid overlap with Navbar */
  padding-bottom: 100px !important; /* Add padding to avoid overlap with Footer */
  background: white !important;
}

.our-story-container {
  width: 100% !important;
  text-align: center !important;
  padding: 60px 50px !important; /* Increased padding */
  margin: 20px !important; /* Added margin */
  border-radius: 10px !important; /* Added border radius */
}

.our-story-title {
  font-size: 3em !important;
  font-family: var(--font-quaternary) !important;
  color: var(--color-visible-text) !important;
  margin-bottom: 20px !important;
}

.our-story-subtitle {
  font-size: 1.2em !important;
  font-family: var(--font-primary) !important;
  margin-bottom: 40px !important;
  color: black !important;
}

.our-story-text {
  font-size: 1.2em !important;
  font-family: var(--font-primary) !important;
  color: black !important;
  margin-bottom: 60px !important;
  line-height: 1.6 !important;
}

.our-story-images {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow images to wrap on smaller screens */
  margin-bottom: 40px;
}

.our-story-image {
  width: 32%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px; /* Add margin to avoid overlap on smaller screens */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.our-story-image:hover,
.our-story-image:focus {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  outline: none;
}

.our-story-footer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 40px !important;
}

.our-story-initials {
  font-size: 2em !important;
  font-family: var(--font-logo) !important;
  color: black !important;
}

.our-story-divider {
  width: 10vw !important;
  height: 2px !important;
  background: black !important;
  margin: 10px 0 !important;
}

.our-story-date {
  font-size: 1.4em !important;
  font-family: var(--font-primary) !important;
  color: black !important;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-image {
  width: 100%;
  height: auto;
  max-height: 80vh; /* Ensure the image doesn't exceed viewport height */
  border-radius: 10px;
  object-fit: contain; /* Maintain aspect ratio */
}

.modal-close-button {
  display: none !important;
}

.modal-close-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* Media Queries */
@media (max-width: 768px) {
  .our-story-title {
    font-size: 2.5em !important;
  }

  .our-story-text {
    font-size: 1em !important;
  }

  .our-story-images {
    flex-direction: column;
    align-items: center;
  }

  .our-story-image {
    width: 80%;
    margin-bottom: 20px;
  }

  .our-story-initials {
    font-size: 1.5em !important;
  }

  .our-story-date {
    font-size: 1em !important;
  }

  .modal-close-button {
    top: -30px;
    right: -30px;
  }

  .modal-image {
    max-height: 70vh; /* Adjust max-height for smaller screens */
  }
}

@media (max-width: 480px) {
  .our-story-title {
    font-size: 2em !important;
  }

  .our-story-text {
    font-size: 0.9em !important;
  }

  .our-story-initials {
    font-size: 1.2em !important;
  }

  .our-story-date {
    font-size: 0.9em !important;
  }

  .modal-close-button {
    top: -25px;
    right: -25px;
  }

  .modal-image {
    max-height: 60vh; /* Further adjust for very small screens */
  }
}
