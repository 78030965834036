@import url("./variables.css");

.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 7vh;
  display: flex;
  align-items: center; /* Center items vertically */
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.logo {
  font-family: var(--font-logo) !important;
  font-size: 2.5em !important;
  color: black;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-decoration: none;
  top: 50%;
  transform: translate(-50%, -50%); /* Center vertically */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.navbar li {
  display: inline;
  white-space: nowrap;
}

.navbar a {
  text-decoration: none;
  font-family: var(--font-primary);
  font-size: 1.5em;
  color: black;
  padding: 10px;
  transition: color 0.3s ease;
}

.navbar a.active {
  border-bottom: 2px solid #d9b8bb;
}

.navbar a:hover {
  color: #d9b8bb !important;
}

.drawer-toggle {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute !important;
  right: 20px !important;
  top: 50% !important;
  transform: translateY(-50%); /* Center vertically */
}

.drawer {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 250px;
  padding: 20px;
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
  transform: translateX(100%);
  opacity: 0;
  z-index: -1;
}

.drawer.open {
  display: flex;
  transform: translateX(0);
  opacity: 1;
}

.drawer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.drawer li {
  margin: 10px 0;
}

.drawer a {
  text-decoration: none;
  font-family: var(--font-primary);
  font-size: 1.5em;
  color: black;
  padding: 10px;
  transition: color 0.3s ease;
}

.drawer a.active {
  border-bottom: 2px solid #d9b8bb;
}

.drawer a:hover {
  color: #d9b8bb;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
  .nav-links {
    gap: 10px;
    justify-content: space-between;
  }

  .nav-links a {
    font-size: 1.2em;
    padding: 5px;
  }

  .navbar {
    height: 10vh;
  }
}

@media (max-width: 768px) {
  .navbar {
    height: 10vh;
  }

  .nav-links {
    display: none; /* Hide the nav links on smaller screens */
  }

  .drawer-toggle {
    display: block; /* Show the drawer toggle button on smaller screens */
    font-size: 1.5em;
  }

  .drawer.open {
    right: 10px;
  }

  .drawer a {
    font-size: 1.2em;
  }

  .logo {
    font-size: 1.9em;
  }

  .drawer-only {
    display: block; /* Show drawer-only links on smaller screens */
  }
}

@media (max-width: 480px) {
  .navbar {
    height: 10vh;
  }
  .drawer {
    width: 200px;
  }
  .drawer a {
    font-size: 1.2em;
    padding: 5px;
  }

  .logo {
    font-size: 1.6em;
  }

  .drawer-toggle {
    font-size: 2.5em;
  }
}

@media (min-width: 769px) {
  .drawer-only {
    display: none; /* Hide drawer-only links on larger screens */
  }
}
