.forgot-password-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  padding: 20px !important;
}

.forgot-password-paper {
  padding: 30px !important;
  max-width: 400px !important;
  width: 100% !important;
  background: #ffffff !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.success {
  margin-top: 10px !important;
  color: green;
}

.error {
  margin-top: 10px !important;
  color: red;
}
