@import url("./variables.css");

.faq-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure it takes the full height */
  padding-top: 100px; /* Add padding to avoid overlap with Navbar */
  padding-bottom: 100px; /* Add padding to avoid overlap with Footer */
  background-image: url("../assets/images/handsWithRing.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Ensure the background image does not zoom */
}

.faq-container {
  padding: 60px 50px !important;
  margin: 20px !important; /* Added margin */
  background-color: #ffffff88 !important;
  border-radius: 10px !important; /* Added border radius */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Added box shadow */
}

.faq-title {
  font-family: var(--font-quaternary) !important;
  font-size: 3.5em !important;
  text-align: center !important;
  color: var(--color-visible-text) !important;
  margin-bottom: 20px !important;
}

.faq-accordion {
  margin-bottom: 10px;
  background-color: #ffffff88 !important;
}

.faq-question {
  font-family: var(--font-secondary) !important;
  font-size: 1.3em !important;
  color: var(--color-visible-text) !important;
}

.faq-answer {
  font-family: var(--font-primary) !important;
  font-size: 1.1em !important;
  color: var(--color-visible-text) !important;
}

.faq-accordion-summary {
  background-color: #9797972b !important;
}

.faq-accordion-expand-icon {
  color: #d3b53d !important;
}

.faq-map-button {
  background-color: var(--color-primary) !important;
  color: var(--color-visible-text) !important;
  border: var(--color-visible-text) 1px solid !important;
  margin-top: 10px !important;
}

.faq-image {
  width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-separator {
  width: 50%; /* Adjust the width as needed */
  height: 2px;
  background-color: var(--color-primary); /* Use your primary color */
  margin: 10px auto; /* Center the separator */
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .faq-container {
    padding: 40px 30px; /* Adjusted padding for smaller screens */
    margin: 15px; /* Adjusted margin for smaller screens */
  }

  .faq-title {
    font-size: 2.5em !important; /* Adjusted font size */
  }

  .faq-question {
    font-size: 1em !important; /* Adjusted font size */
  }

  .faq-answer {
    font-size: 0.9em !important; /* Adjusted font size */
  }
}

@media (max-width: 480px) {
  .faq-container {
    padding: 30px 20px; /* Adjusted padding for smaller screens */
    margin: 10px; /* Adjusted margin for smaller screens */
  }

  .faq-title {
    font-size: 2em !important; /* Adjusted font size */
  }

  .faq-question {
    font-size: 0.9em !important; /* Adjusted font size */
  }

  .faq-answer {
    font-size: 0.8em !important; /* Adjusted font size */
  }
}
