@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Playfair+Display:wght@400;700&display=swap");
@import "./variables.css";

.privacy-policy-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 60px; /* Ensure content is below the Navbar */
  padding-bottom: 40px; /* Ensure content is above the Footer */
  background-color: #f9f9f9;
}

.privacy-policy-container {
  text-align: left;
  padding: 50px 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: var(--color-primary);
  font-weight: normal;
  width: 100%;
  max-width: 800px;
}

.privacy-policy-title {
  font-size: 2.5em;
  margin-bottom: 30px;
  font-family: var(--font-secondary);
  color: #333;
}

.privacy-policy-content {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
}

.privacy-policy-content strong {
  color: #333;
}
